<template>
  <banner :list="list"></banner>

  <div class="list-container">

    <list url="/M/Server/GetMoreActivity" v-show="true">
      <template v-slot:default="data">
        <news-item :newData="data"></news-item>
      </template>
    </list>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, onMounted, onUnmounted } from "vue";
  // components
  import banner from "@/components/common/Swiper.vue";
  import list from "@/components/common/list.vue";
  import newsItem from "@/components/common/ActivityItem.vue";
  import api from "@/api/axios";

  export default defineComponent({
    name: "Activity",
    components: {
      banner,
      list,
      newsItem,
    },
    setup() {
      //  切换列表菜单
      const list = ref(null);
      api.get("/M/Discover").then((res): void => {
        list.value = res.data.obj.listimg;
      });
      type Menu = "全部";
      const menu: Menu[] = ["全部"];
      const currentMenuItem = ref(menu[0]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      return {
        list,
        menu,
        currentMenuItem,
        selectMenu,
      };
    },
  });
</script>

<style lang="scss" scoped>
  //  list  nav
  .menu-nav-wapper {
    overflow: hidden;
    height: 45px;
    width: 375px;
    line-height: 45px;
    position: fixed;
    top: 55px;
    z-index: 100;
    background-color: #fff;
    border-bottom: 1.012px solid #f4f4f4;
    .menu-nav {
      overflow: hidden;
      height: 45px;
      width: 100%;
      line-height: 45px;
      border-bottom: 1px solid #f4f4f4;
      top: 0;
      z-index: 100;
      background-color: #fff;
    }
    li {
      float: left;
      width: 25%;
      text-align: center;
      a {
        display: block;
        text-align: center;
        line-height: 45px;
        width: 100%;
        font-size: 16px;
        color: #666;
      }
    }

    .active a {
      color: #df0024;
    }
  }
</style>
