
  import { defineComponent, ref, onMounted, onUnmounted } from "vue";
  // components
  import banner from "@/components/common/Swiper.vue";
  import list from "@/components/common/list.vue";
  import newsItem from "@/components/common/ActivityItem.vue";
  import api from "@/api/axios";

  export default defineComponent({
    name: "Activity",
    components: {
      banner,
      list,
      newsItem,
    },
    setup() {
      //  切换列表菜单
      const list = ref(null);
      api.get("/M/Discover").then((res): void => {
        list.value = res.data.obj.listimg;
      });
      type Menu = "全部";
      const menu: Menu[] = ["全部"];
      const currentMenuItem = ref(menu[0]);
      function selectMenu(item: Menu) {
        currentMenuItem.value = item;
      }

      return {
        list,
        menu,
        currentMenuItem,
        selectMenu,
      };
    },
  });
